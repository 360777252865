<template>
  <v-container class="pa-0 body-card">
    <div id="spacer"></div>
    <div class="PaymentMethodList">
      <div class="background">
      </div>
      <div class="item-add">
        <h4 class="h4-title pb-1">{{$t('MyWallet')}}</h4>
        <v-row v-if="payments.length>0 && ($partnerACL.isShowButton('detail') || $partnerACL.isShowButton('edit'))">
          <v-col cols="12" class="pt-0 pb-0" v-for="(item) in payments" :key="item.customer_sof_id">
            <v-list-item class="onhover pr-0" @click="detailPayment(item)">
              <v-list-item-avatar>
                <v-img
                  :src="item.wallet_info.wallet_img"
                >
                </v-img>
              </v-list-item-avatar>
              <div class="item-border border-bottom">
                <v-list-item-title>
                  <v-row>
                    <v-col class="black--text">
                      {{ item.wallet_info.wallet_name }}
                    </v-col>
                    <v-col class="text-end grey--text">
                      {{$t(item.wallet_info.anotation)}}
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <v-list-item-action class="ml-0 pr-5">
                  <v-icon color="grey darken"> mdi-chevron-right </v-icon>
                </v-list-item-action>
              </div>
            </v-list-item>
            <!-- <hr :key="index" 
                v-if="index < items.length - 1"> -->
          </v-col>
        </v-row>
        <!-- <v-row v-else>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item class="onhover pr-0">
              <div class="item-border border-bottom">
                <v-list-item-title>
                  <v-row>
                    <v-col class="grey--text text-center">
                      {{$t("NoWallet")}}
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </div>
            </v-list-item>
          </v-col>
        </v-row> -->
      </div>
      <div id="spacer"></div>
      <div
          v-if="$partnerACL.isShowButton('add')"
          class="item-add"
        >
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item class="onhover" @click="AddPaymentMethod()">
              <v-list-item-avatar>
                <v-img
                  class="img-add"
                  :src="require('@/assets/add-payment.svg')"
                >
                </v-img>
              </v-list-item-avatar>
              <!-- <v-list-item-title> -->
                <v-row>
                  <v-col cols="12" class="black--text">
                    <p class="mb-1">{{$t('AddWallet')}}</p>
                    <p class="mb-0 grey--text text">{{$t('describeAddWallet')}}</p>
                  </v-col>
                </v-row>
              <!-- </v-list-item-title> -->
              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'PaymentMethodList',
    data() {
      return {
        selected: [2],
        payments: [],
        items: [
          {
            title: 'MOCA',
            wallet_img:require('../../assets/moca.png'),
          },
          {
            title: 'ZALOPAY',
            wallet_img:require('../../assets/zalopay.png'),
          },
          {
            title: 'MOMO',
            wallet_img:require('../../assets/momo.png'),
          },
        ],
        fullName : ''
      }
    },
    methods:{
      AddPaymentMethod(){
        // this.$router.push({
        //   name: 'PaymentMethodAdd',
        // })
        const addButton = this.$partnerACL.getMetaButton('add');
        if(addButton){
          if(addButton.menu_type == "wsc_menu"){
              this.$router.push({
                name: addButton.redirect_name,
              });
          }else {
            window.location = addButton.redirect_path;
          }
        } else {
          this.$router.push({
            name: "PaymentMethodAdd",
          });
        }
      },
      detailPayment(item){
        // this.$router.push({
        //   name: 'PaymentMethodDetail',
        //   params : {
        //     items: item
        //   }
        // })
        const detailButton = this.$partnerACL.getMetaButton('detail') || this.$partnerACL.getMetaButton('edit');
        if(detailButton){
          if(detailButton.menu_type == "wsc_menu"){
              this.$router.push({
                name: detailButton.redirect_name,
                params : {
                  items: item
                }
              });
          }else {
            window.location = detailButton.redirect_path;
          }
        } else {
          this.$router.push({
            name: "PaymentMethodDetail",
            params : {
              items: item
            }
          });
        }
      },
    },
    mounted(){
      this.$store
        .dispatch("customer/getPaymentMethod")
        .then((response) => {
          if(response.data){
            const responseData = response.data;
            this.payments = responseData.map(data=>{
              return {
                ...data,
                wallet_info: {
                  ...data.wallet_info,
                  // wallet_img: data.wallet_id == 1 ? require('../../assets/moca.png') : (data.wallet_id == 2 ? require('../../assets/zalopay.png') : require('../../assets/momo.png')),
                  anotation: data.priority == 1 ? "default" : ""
                }
              }
            })
            // this.payments = {
            //   ...response.data,
            //   wallet: response.data.wallet_info.map(wallet=>{
            //     return {
            //       ...wallet,
            //     }
            //   })
            // }
            // for (let data of response.data){
            //   if (data.wallet_id == 1) {
            //     data.wallet_img = require('../../assets/moca.png')
            //   }else if (data.wallet_id == 2) {
            //     data.wallet_img = require('../../assets/zalopay.png')
            //   }else {
            //     data.wallet_img = require('../../assets/momo.png')
            //   }

            //   if (data.priority == 1) {
            //     data.anotation = "default"
            //   }
            //   this.payments.push(data)
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
</script>

<style lang="scss" scoped>
.item-border {
    display: flex;
    width: 100%;
}
.body-card {
  background-color: #f5fff7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}
#spacer {
  background: #f5fff7;
  padding: 6px;
}
.border-bottom {
  border-bottom: 1px solid #F5F5F5;
  width: 100%;
}
.text {
  font-size : 0.850rem;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
   .background {
    width: 100%;
    height: 0.5%;
    padding-top: 0.2%;
    background-color: #f5fdf7;
  }

  .annotation{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 118.88%;
    /* identical to box height, or 17px */

    text-transform: capitalize;

    color: #B2B2B2, 100%;
  }
  .item-add{
    background-color: #FFFFFF;
    width: 100%;
  }
  .h4-title{
    padding-top: 10px;
    padding-left: 10px;
  }
  .describe-add {
    margin-top:-20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 118.88%;
    /* or 14px */

    color: #9B9B9B;
  }
  .img-add{
    height: auto;
    max-width: 20%;
  }
  .title-add{
    margin-top:0.3%;
  }
  .onHover{
    cursor: pointer;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {

  .background {
    width: 100%;
    height: 2%;
    padding-top: 1%;
    background-color: #f5fdf7;
  }

  .item-add{
    width: 100%;
    background-color: #FFFFFF;
  }
  .annotation{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 118.88%;
    /* identical to box height, or 17px */

    text-transform: capitalize;

    color: #B2B2B2, 100%;
  }
  .h4-title{
    padding-top: 10px;
    padding-left: 10px;
  }
  .describe-add {
    margin-top:-20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 118.88%;
    /* or 14px */

    color: #9B9B9B;
  }
  .img-add{
    width: 40px;
    height: auto;
  }
  .title-add{
    margin-top:0.3%;
  }
  .onHover{
    cursor: pointer;
  }
}
</style>